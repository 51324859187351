/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2024-08-27T14:52:22.038Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.3.10300-1532290';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
